<template>
	<main
		class="sportfile padding-24 bp-1024:padding-64"
		id="sportfile-view"
		v-if="sportfile"
	>
		<h1 class="header-font font-size-36 margin-0 margin-b-24 text-brand">
			{{ sportfile.title }}
		</h1>
		<img
			v-if="sportfile.image"
			:src="$root.get_image_resizer_url(`${$root.proxy}${headerImage}`)"
			:alt="sportfile.title"
		/>
		<div class="sportfile__content" v-html="sportfile.body"></div>
	</main>
</template>

<script>
import { get } from '@/helpers';

export default {
	name: 'Sportfile',

	props: {
		year: {
			type: [Number, String],
			default: null,
		},
		month: {
			type: [Number, String],
			default: null,
		},
		day: {
			type: [Number, String],
			default: null,
		},
		filename: {
			type: String,
			default: null,
		},
		slug: {
			type: String,
			default: null,
		},
	},

	data: () => ({
		sportfile: null,
		accordions_setup: false,
	}),

	computed: {
		headerImage() {
			let removeFrom = 'sidearmsports.com';
			if (
				this.sportfile &&
				this.sportfile.image &&
				this.sportfile.image.original
			) {
				return this.sportfile.image.original.substring(
					this.sportfile.image.original.indexOf(removeFrom) +
						removeFrom.length,
				);
			}

			return '';
		},
	},

	methods: {
		get_sportfile() {
			return new Promise((resolve, reject) => {
				let request_options = {};
				if (this.slug) {
					let _slug = this.slug;
					request_options.slug = _slug;
				} else {
					request_options.date =
						'' + this.month + '/' + this.day + '/' + this.year;
					request_options.filename = this.filename.split('.aspx')[0];
				}

				get(
					`${this.$root.proxy}/services/sportfile.ashx/get`,
					request_options,
				)
					.then(response => {
						if (!response.url_slug) {
							this.$router.push({ name: 'Missing' });
						}
						this.sportfile = response;

						if (!this.slug) {
							this.$router.replace(this.sportfile.url_slug);
						}

						this.setMetaSidearm(this.sportfile.title, [
							/* {
								name: 'description',
								content: description,
							}, */
							{
								name: 'og:title',
								content: this.sportfile.title,
							},
							/* {
								name: 'og:description',
								content: description,
							}, */
							{
								name: 'twitter:title',
								content: this.sportfile.title,
							},
							/* {
								name: 'twitter:description',
								content: description,
							},
							{
								name: 'og:image',
								content: image,
							},
							{
								name: 'twitter:image',
								content: image,
							}, */
						]);

						resolve();
					})
					.catch(error => {
						console.log(error);
						this.$router.push({ name: 'Missing' });
						reject();
					});
			});
		},

		setup_accordions() {
			let accordions = document.querySelectorAll('.sidearm-accordion');
			let accordion_id = 0;

			accordions.forEach(accordion => {
				accordion.setAttribute('id', `accordion_${accordion_id}`);

				let headers = accordion.querySelectorAll('h3');
				let header_id = 0;

				headers.forEach(header => {
					let panel = header.nextElementSibling;

					panel.setAttribute(
						'id',
						`panel_${accordion_id}_${header_id}`,
					);

					let panel_content = panel.innerHTML;
					panel.innerHTML = `<div class="panel-inner">${panel_content}</div>`;

					if (header.lastElementChild) {
						header.lastElementChild.remove();
					}

					header.insertAdjacentHTML(
						'beforeend',
						'<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" stroke="#7C7E81"><path d="M12 5V19" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/><path d="M19 12L12 19L5 12" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>',
					);
					header.setAttribute('role', 'button');
					header.setAttribute('tabindex', '0');
					header.setAttribute('aria-expanded', 'false');
					header.setAttribute(
						'aria-controls',
						`panel_${accordion_id}_${header_id}`,
					);
					header.addEventListener('click', handle_header_click);
					header.addEventListener('keyup', e => {
						if (e.keyCode === 13) {
							handle_header_click(e);
						}
					});

					function handle_header_click(e) {
						if (header.classList.contains('active')) {
							header.classList.remove('active');
							header.setAttribute('aria-expanded', 'false');
							panel.classList.remove('active');
						} else {
							header.classList.add('active');
							header.setAttribute('aria-expanded', 'true');
							panel.classList.add('active');
						}
					}

					header_id++;
				});

				accordion_id++;
			});

			this.accordions_setup = true;
		},
	},

	watch: {
		$route(to) {
			this.accordions_setup = false;
			if (to.name === 'Sportfile') {
				this.get_sportfile().then(() => {
					if (!this.accordions_setup) {
						this.setup_accordions();
					}
				});
			}
		},
	},

	created() {
		this.get_sportfile().then(() => {
			if (!this.accordions_setup) {
				this.setup_accordions();
			}
		});
	},
};
</script>

<style lang="scss">
#sportfile-view {
	.sidearm-accordion {
		> h3 {
			align-items: center;
			border: 1px solid var(--color-default-theme-stroke);
			color: var(--color-default-theme-muted-text);
			cursor: pointer;
			display: flex;
			font-size: 1rem;
			font-weight: 700;
			justify-content: space-between;
			min-height: 72px;
			margin: 0;
			padding: 18px 24px;
			transition: all 0.25s;

			> svg {
				flex: 0 0 auto;
				margin: 0 0 0 12px;
				transition: transform 0.25s, stroke 0.25s;
			}

			&.active {
				background-color: var(--color-default-theme-surface);
				color: var(--color-default-theme-default-text);

				> svg {
					stroke: var(--color-primary);
					transform: rotate(180deg);
				}
			}
		}

		.panel-inner {
			opacity: 0;
			transform: translateX(12px);
			transition: all 0s;
		}

		> div {
			height: 0;
			overflow: hidden;
			margin: 0 0 8px;

			:first-child {
				margin-top: 0;
			}

			:last-child {
				margin-bottom: 0;
			}
		}

		> div.active {
			background-color: var(--color-default-theme-surface);
			border: 1px solid var(--color-default-theme-stroke);
			border-top: none;
			height: auto;
			padding: 18px 24px;

			.panel-inner {
				opacity: 1;
				transform: translateX(0);
				transition: all 0.5s;
			}
		}

		@media screen and (min-width: 768px) {
			> h3 {
				padding: 24px 32px;
			}
			> div.active {
				padding: 24px 32px;
			}
		}
	}
}
</style>
